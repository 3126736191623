.input {
  width: calc(100% - 40px);
}

.input :global .MuiInputBase-input {
  padding: var(--space-1) var(--space-2);
}

.input input[type='text'] {
  padding-left: 0;
  padding-right: 0;
}

.input [title] {
  font-weight: bold;
  color: var(--color-text-primary);
}

.value {
  width: 100%;
}